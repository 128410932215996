<template>
  <div class="Campaign">
    <div class="no-content" v-if="!campaign">
      <h2>Loading...</h2>
    </div>
    <div class="content" v-else>
      <div :style="{ 'background-color': campaign.pageData.bgBanner }">
        <v-img :src="campaign.pageData.bannerUrl" max-width="1200" class="mx-auto"></v-img>
      </div>
      <v-container>
        <v-row id="campaign" class="text-center my-0">
          <v-col cols="12">
            <!-- <h2 class="text-h4 primary--text text--darken-3 font-weight-light font-italic py-8"> -->
            <h2
              class="text-h4 font-weight-light font-italic py-8"
              :style="{ color: companiesDict[campaign.ownerId].color }"
            >
              CAMPANHA
            </h2>
            <div class="text-left px-4">
              <div class="subtitle-1">
                <span>Cliente</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.client }}</span>
              </div>
              <div class="subtitle-1">
                <span>Campanha</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.name }}</span>
              </div>
              <div class="subtitle-1">
                <span>Produto</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.product }}</span>
              </div>
              <div class="subtitle-1">
                <span>Peça</span>:
                <span class="font-weight-bold text-uppercase">
                  {{ campaign.type }} {{ campaign.format }}
                </span>
              </div>
              <div class="subtitle-1">
                <span>Veiculação</span>:
                <span class="font-weight-bold text-uppercase">{{ campaign.timeFrame }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row id="radios" class="text-center my-0">
          <v-col cols="12">
            <h2
              class="text-h4 font-weight-light font-italic py-8"
              :style="{ color: companiesDict[campaign.ownerId].color }"
            >
              RÁDIOS SELECIONADAS
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-simple-table
                v-if="campaign.id == 'programa-de-testagem-covid-19'"
                dense
                height="100%"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="primary darken-2 text-left white--text">ID. SECOM</th>
                      <th class="primary darken-2 text-left white--text">MUNICÍPIO</th>
                      <th class="primary darken-2 text-left white--text">UF</th>
                      <th class="primary darken-2 text-left white--text">RÁDIO</th>
                      <th class="primary darken-2 text-left white--text">FX</th>
                      <th class="primary darken-2 text-left white--text">PRX</th>
                      <th class="primary darken-2 text-left white--text">PROGRAMA</th>
                      <th class="primary darken-2 text-left white--text">APRESENTADOR</th>
                      <th class="primary darken-2 text-left white--text">INÍCIO</th>
                      <th class="primary darken-2 text-left white--text">FIM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(radio, i) in campaign.radiosData"
                      :key="i"
                      :class="{ grey: isOdd(i), 'lighten-5': isOdd(i) }"
                    >
                      <td class="text-left">{{ radio.idSecom }}</td>
                      <td class="text-left">{{ radio.municipio }}</td>
                      <td class="text-left">{{ radio.uf }}</td>
                      <td class="text-left">{{ radio.name }}</td>
                      <td class="text-left">{{ radio.fx }}</td>
                      <td class="text-left">{{ radio.prx }}</td>
                      <td class="text-left">{{ radio.programa }}</td>
                      <td class="text-left">{{ radio.apresentador }}</td>
                      <td class="text-left">{{ radio.inicio }}</td>
                      <td class="text-left">{{ radio.fim }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table
                v-else
                fixed-header
                :dense="isMobile ? true : false"
                :height="
                  isMobile
                    ? campaign.radiosData.length > 10
                      ? 674
                      : '100%'
                    : campaign.radiosData.length > 10
                    ? 530
                    : '100%'
                "
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <!-- <th class="primary darken-2 text-left white--text">ID. SECOM</th> -->
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        ID. SECOM
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        MUNICÍPIO
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        UF
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        RÁDIO
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        FX
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        PRX
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        PROGRAMA
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        APRESENTADOR
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        INÍCIO
                      </th>
                      <th
                        class="text-left white--text"
                        :style="{ backgroundColor: companiesDict[campaign.ownerId].color }"
                      >
                        FIM
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(radio, i) in campaign.radiosData"
                      :key="i"
                      :class="{ grey: isOdd(i), 'lighten-5': isOdd(i) }"
                    >
                      <td class="text-left">{{ radio.idSecom }}</td>
                      <td class="text-left">{{ radio.municipio }}</td>
                      <td class="text-left">{{ radio.uf }}</td>
                      <td class="text-left">{{ radio.name }}</td>
                      <td class="text-left">{{ radio.fx }}</td>
                      <td class="text-left">{{ radio.prx }}</td>
                      <td class="text-left">{{ radio.programa }}</td>
                      <td class="text-left">{{ radio.apresentador }}</td>
                      <td class="text-left">{{ radio.inicio }}</td>
                      <td class="text-left">{{ radio.fim }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="campaign">
        <v-row id="content" class="text-center my-0">
          <v-col cols="12">
            <h2
              class="text-h4 font-weight-light font-italic py-8"
              :style="{ color: companiesDict[campaign.ownerId].color }"
            >
              CONTEÚDO DIVULGADO
            </h2>
            <div class="text-left px-4">
              <p class="font-weight-bold">
                <span>{{ campaign.client }}</span> <br />
                <span>
                  {{ campaign.name }}
                </span>
                <br />
                <span>{{ campaign.type }} {{ campaign.format }}</span> <br />
              </p>

              <div v-if="campaign.id == 'campanha-nacional-cuida-mais-brasil'">
                <p><b> PEÇA 01: CUIDA MAIS BRASIL</b></p>

                <div style="margin-left: 20px">
                  <p>
                    É, minha gente… Vocês sabem… A gente sempre sonha com o melhor pros nossos
                    filhos, né? Com eles crescendo fortes e com saúde!
                  </p>
                  <p>
                    É por isso que, como pai, eu fico muito feliz em contar pra todas as famílias
                    que, agora, com o Cuida Mais Brasil, mais crianças e mulheres vão poder ter
                    acesso a atendimento de qualidade com pediatras e ginecologistas-obstetras na
                    <i>Atenção Primaria à Saúde</i> em todo o país.
                  </p>
                  <p>
                    É mais cuidado desde o início da vida. Isso mesmo, na Unidade Básica de Saúde
                    aqui pertinho e na mais perto daí, de onde você mora também. O Cuida Mais Brasil
                    já começou a levar mais atenção e cuidado pro Brasil inteiro.
                  </p>
                  <p>É isso.... Cuida Mais Brasil. Cuidar para crescer e viver melhor.</p>
                </div>

                <p style="margin-top: 40px">
                  <b>PEÇA 02:CUIDA MAIS BRASIL (TESTEMUNHAL COMUNICADOR 2)</b>
                </p>

                <div style="margin-left: 20px">
                  <p>
                    É, minha gente… Vocês sabem… Quem é pai ou mãe sempre sonha com o melhor pros
                    filhos, né? Com eles crescendo fortes e com saúde!
                  </p>
                  <p>
                    É por isso que eu fico muito feliz em contar pra todas as famílias que, agora,
                    com o Cuida Mais Brasil, mais crianças e mulheres vão poder ter acesso a
                    atendimento de qualidade com pediatras e ginecologistas-obstetras na
                    <i>Atenção Primária à Saúde</i> em todo o país.
                  </p>
                  <p>
                    É mais cuidado desde o início da vida. Isso mesmo, na Unidade Básica de Saúde
                    aqui pertinho e na mais perto daí, de onde você mora também. O Cuida Mais Brasil
                    já começou a levar mais atenção e cuidado pro Brasil inteiro.
                  </p>
                  <p>É isso... Cuida Mais Brasil. Cuidar para crescer e viver melhor.</p>
                </div>
              </div>

              <div v-if="campaign.id == 'programa-de-testagem-covid-19'">
                <p>
                  BG: Mapa do Brasil apontando diversos municípios com postos de testagem, logos e
                  lettering "PLANO NACIONAL DE EXPANSÃO DA TESTAGEM PARA O NOVO CORONAVÍRUS".
                </p>
                <p>
                  <span>LOC. APRESENTADOR(A)</span><br />
                  Já são milhões de brasileiros vacinados contra a Covid-19. E para seguir com mais
                  segurança, o Governo Federal intensifica o Plano Nacional de Expansão da Testagem
                  para o Novo Coronavírus com testes rápidos. Em todo o Brasil, será reforçada a
                  testagem nas unidades de saúde e nos locais com grande circulação de pessoas
                  também, para reduzir a transmissão do vírus no país.
                </p>
                <p>
                  O teste não dói, não incomoda, é gratuito e em apenas 15 minutos você já tem o
                  resultado! Com a testagem em massa, o Ministério da Saúde pode monitorar melhor o
                  índice de contágio e as novas variantes, além das pessoas que estão contaminadas,
                  mesmo as que não tiverem sintomas.
                </p>
                <p>
                  E quanto mais pessoas testadas, mais cuidamos uns dos outros e podemos circular
                  com mais segurança. Faça o teste nos postos de saúde, UPAs ou postos de testagem
                  da sua cidade, beleza?
                </p>
                <p>Plano Nacional de Expansão da Testagem. Vamos testar. E seguir com segurança.</p>
              </div>

              <div
                v-if="
                  campaign.id == 'campanha-nacional-de-multivacinacao-para-criancas-e-adolescentes'
                "
              >
                <p class="font-weight-bold">
                  <span>PEÇA: B - TESTEMUNHAL DIA D 15/10/21</span> <br />
                  <span>MULTIVACINAÇÃO</span>
                  <br />
                  <span>Textos Merchan</span>
                </p>
                <p>DIA D</p>
                <p>
                  <span>Texto base 60"</span><br />
                  Atenção, papais, mamães e responsáveis. Chegou a hora da gente falar sobre
                  vacinação. Gente, neste sábado, dia 16 de outubro, é dia de atualizar a caderneta
                  de vacinação dos seus filhos. É isso mesmo, dia de levar as crianças e
                  adolescentes menores de 15 anos de idade ao posto de vacinação mais perto de você
                  para atualizar a caderneta com as vacinas que estão faltando. São 18 vacinas
                  disponíveis para proteger seus filhos e sua família de doenças como poliomielite,
                  sarampo, rubéola, caxumba, entre muitas outras.
                </p>
                <p>
                  No site gov.br/saude você encontra todas as vacinas disponíveis para a proteção
                  das crianças e adolescentes, dê uma olhada! Não se esqueça! É neste sábado, dia 16
                  de outubro, e tem que levar a caderneta de vacinação, viu?
                </p>
                <p>
                  Quando protegemos nossas crianças, protegemos o país inteiro. Entre nesse
                  movimento. É o...Movimento Vacina Brasil! É mais proteção para todos!
                </p>
                <div class="text-left px-4 mt-12">
                  <p class="font-weight-bold">
                    <span>PEÇA: C - TESTEMUNHAL PERIODO 22 A 26/10/21</span>
                    <br />
                    <span>MULTIVACINAÇÃO</span>
                    <br />
                    <span>Textos Merchan</span>
                  </p>
                  <p>PERÍODO</p>
                  <p>
                    <span>Texto base 60"</span><br />
                    Pessoal, vamos falar da Campanha de Multivacinação? Esse é um recado para todos
                    os pais, mães e responsáveis: tá na hora de atualizar a caderneta de vacinação
                    para crianças e adolescentes menores de 15 anos de idade. É isso aí, de 1º a 29
                    de outubro leve seus filhos ao posto de vacinação mais perto de você para
                    atualizar a caderneta com as vacinas que estão faltando. São 18 vacinas
                    disponíveis para proteger seus filhos e sua família de doenças como
                    poliomielite, sarampo, rubéola, caxumba, entre muitas outras. São doenças muito
                    perigosas que podem prejudicar o futuro dos seus pequenos!
                  </p>
                  <p>
                    Saiba mais no site gov.br/saude onde você encontra todas as vacinas disponíveis
                    nessa campanha, dê uma olhada! Vamos vacinar e proteger nossas crianças e
                    adolescentes? Não se esqueça de levar a caderneta, viu? Faça parte desse
                    movimento! É o...Movimento Vacina Brasil. É mais proteção para todos!
                  </p>
                </div>
                <div class="text-left px-4 mt-12">
                  <p class="font-weight-bold">
                    <span>PEÇA: D - TESTEMUNHAL ULTIMOS 27 A 29/10/21 </span>
                    <br />
                    <span>MULTIVACINAÇÃO</span>
                    <br />
                    <span>Textos Merchan</span>
                  </p>
                  <p>ÚLTIMOS DIAS</p>
                  <p>
                    <span>Texto base 60"</span><br />
                    Agora é o momento de falar de saúde e da proteção das crianças e adolescentes.
                    Pais, mães e responsáveis, o período de atualizar a caderneta de vacinação dos
                    seus filhos está chegando ao fim! Até 29 de outubro você pode levar as crianças
                    e adolescentes menores de 15 anos de idade ao posto de vacinação mais perto de
                    você para atualizar a caderneta com as vacinas que estão faltando. São 18
                    vacinas disponíveis para proteger seus filhos e sua família de doenças como
                    poliomielite, sarampo, rubéola, caxumba, entre muitas outras. São doenças muito
                    perigosas que podem prejudicar o futuro dos seus pequenos!
                  </p>
                  <p>
                    Saiba mais no site gov.br/saude onde você encontra todas as vacinas disponíveis
                    nessa campanha, dê uma olhada! Vamos vacinar e proteger nossas crianças e
                    adolescentes? Não se esqueça de levar a caderneta, viu? Faça parte desse
                    movimento! É o...Movimento Vacina Brasil. É mais proteção para todos!
                  </p>
                  <p>
                    No site gov.br/saude você encontra todas as vacinas disponíveis nessa campanha,
                    dê uma olhada! Vamos vacinar e proteger nossas crianças e adolescentes? São os
                    últimos dias, hein? Não se esqueça de levar a caderneta, combinado?
                  </p>
                  <p>Movimento Vacina Brasil. É mais proteção para todos!</p>
                </div>
              </div>

              <div v-if="campaign.id == 'campanha-nacional-de-prevencao-a-variola-dos-macacos'">
                <strong>LOCUTOR:</strong>
                <p>
                  Vocês já devem ter visto notícias nos jornais e pelas redes sociais sobre a
                  Varíola dos Macacos, não é?
                </p>
                <p>
                  O mais importante agora é ter a informação segura e confiável. No site
                  <strong>www.gov.br/varioladosmacacos</strong>, o Ministério da Saúde organizou
                  tudo o que você precisa saber para se cuidar.
                </p>
                <p>Algumas informações importantes eu já vou adiantar!</p>
                <p>
                  A transmissão da Varíola dos Macacos ocorre pelo contato físico de uma pessoa
                  infectada para outra pessoa, por secreções e fluidos corporais, ou por meio de
                  objetos contaminados.
                </p>
                <p>
                  Os principais sinais e sintomas são lesões na pele, febre, inchaço dos gânglios,
                  dor de cabeça, dores no corpo, calafrios e exaustão.
                </p>
                <p>Em caso de sinais e sintomas, procure imediatamente uma Unidade de Saúde.</p>
                <p>
                  Para saber mais, acesse
                  <strong> www.gov.br/varioladosmacacos. </strong>
                </p>
                <p>Fique bem com a informação certa.</p>
                <p>Ministério da Saúde</p>
              </div>

              <div v-if="campaign.id == 'prevencao-e-combate-a-queimadas'">
                <strong>LOCUTOR:</strong>
                <p>
                  A seca chegou com força esse ano, não é mesmo? E isso é muito preocupante, pois
                  quanto maior a seca, maiores são os riscos de incêndios em matas e florestas.
                </p>
                <p>
                  O que muita gente ainda não sabe é que o simples ato de riscar um fósforo para
                  queimar lixo ou jogar uma bituca de cigarro é uma das principais ameaças à flora e
                  à fauna.
                </p>
                <p>Na seca, qualquer fagulha é suficiente para começar um incêndio.</p>
                <p>
                  E a natureza é a maior vítima. Mas a saúde das pessoas e a sua própria também são
                  prejudicadas.
                </p>
                <p>Provocar queimadas é crime, sujeito a multa e prisão.</p>
                <p>
                  Caso você veja alguém praticando esse ato prejudicial ao nosso meio ambiente, e a
                  todos nós, ligue para 193 e denuncie.
                </p>
                <p>A natureza agradece.</p>
                <p>Governo do Distrito Federal</p>
              </div>

              <div v-if="campaign.id == 'variola-dos-macacos'">
                <strong>LOCUTOR:</strong> <br />
                <p>FALAR DATA E HORÁRIO</p>
                <p>Você já deve ter ouvido falar por aí na varíola dos macacos, não é?</p>
                <p>
                  Ela é causada por um vírus e pode ser transmitida por meio do contato físico, pela
                  troca de fluidos corporais e até por algum objeto que possa estar infectado, como
                  um alicate de unha, por exemplo.
                </p>
                <p>
                  Então, é preciso redobrar os cuidados para não se infectar. Os sintomas mais
                  comuns são lesões na pele, febre, dor de cabeça, fraqueza e inchaço nos gânglios,
                  que você deve conhecer como ínguas. E o tempo de infecção leva entre 5 e 21 dias.
                </p>
                <p>
                  Mas calma, as Unidades de Saúde estão prontas para te receber e fazer o
                  atendimento. Em caso de aparecimento dos sintomas você deve usar roupas que cubram
                  as lesões, manter distanciamento social e ir até a Unidade de Saúde mais próxima.
                  O exame é feito coletando amostras das feridas e de sangue.
                </p>
                <p>
                  Para saber mais, <strong> acesse www.saude.df.gov.br.</strong> <br />Varíola dos
                  Macacos. Conhecer para prevenir.
                </p>
                <p>Governo do Distrito Federal</p>
              </div>

              <div v-if="campaign.id == 'feminicidio'">
                <strong>LOCUTOR:</strong> <br />
                <p>
                  Preciso da sua atenção, aqui, agora! Vamos falar de um assunto muito importante
                  para a segurança das mulheres.
                </p>
                <p>
                  Nos últimos meses, o número de casos de violência contra as mulheres cresceu
                  muito. Infelizmente, essa é uma realidade triste. Algumas providências já foram
                  tomadas. Na Ceilândia, foram construídas uma delegacia especializada e a Casa da
                  Mulher Brasileira. Uma nova lei obriga cobradores e motoristas a acionarem a
                  polícia, caso testemunhem qualquer ato inconveniente dentro do ônibus. E a
                  campanha do Sinal Vermelho entrou em vigor.
                </p>

                <p>Violência contra a mulher é crime. Omissão é cumplicidade.</p>

                <p>Faça a sua parte. Denuncie pelo número 197, opção 3.</p>

                <p>Governo do Distrito Federal</p>
              </div>

              <div v-if="campaign.id == 'doacao-de-leite-materno-2023'">
                <strong>LOCUTOR:</strong> <br />
                <p>Gente... agora vou falar de um assunto de vida: a doação de leite materno.</p>
                <p>
                  Você sabia que um bebê prematuro precisa de uns 30 a 40 ml de leite por dia para
                  sobreviver? Você ouviu certo: 40 ml de leite por dia é o suficiente para esse bebê
                  continuar vivendo.
                </p>

                <p>
                  O leite materno é o alimento mais completo que existe e tudo o que os bebês
                  precisam nos 6 primeiros meses de vida. Por isso, o meu apelo aqui é para as mães
                  que estão amamentando seus filhos. Doem um pouco do seu leite...
                </p>

                <p>
                  A natureza é tão sábia que quanto mais leite tira, mais leite vai produzir. E para
                  esses bebês que estão no hospital, cada ml faz uma grande diferença.
                </p>

                <p>
                  Para doar, basta ligar um meia zero (160), opção 4 e vão te passar todas as
                  orientações.
                </p>

                <p>
                  E tem mais: não importa a quantidade de leite, os bombeiros vão na sua casa
                  buscar. Doação de leite materno: toda gota conta.
                </p>

                <p>Governo do Distrito Federal</p>
              </div>

              <div v-if="campaign.id == 'combate-a-dengue-2023'">
                <strong>Apresentador, em tom sério e coloquial:</strong> <br />
                <p>
                  Pessoal, é hora de falar muito sério agora. É tempo de chuva! Você tá vendo a
                  quantidade de tempestades aí pelas nossas cidades. É hora de ficar ainda mais
                  atento a um problema que dá pra você cuidar e prevenir: o mosquito da dengue.
                </p>

                <p>
                  Olha só que informação impressionante! Você sabia que um só mosquito é capaz de
                  infectar até 20 pessoas?
                </p>

                <p>
                  Então, todo cuidado é pouco, gente. Não deixe sacos de lixo ou entulho acumulado
                  no seu terreno. Não deixe água parada em recipientes como vasos de plantas,
                  baldes, pneus, calhas, garrafas, tampas, caixas-d’água ou piscinas sem manutenção.
                </p>

                <p>
                  E atenção! Mantenha lixeiras e caixas-d’água sempre tampadas. Caso você veja um
                  terreno perto de você cheio de lixo ou entulho acumulando água, denuncie pelo um,
                  meia, zero.
                </p>

                <p>
                  O GDF tá na rua conferindo as casas e terrenos, mas faça o mesmo em sua própria
                  casa. Vamos ficar ligados! A dengue não para de matar. Não podemos parar de
                  cuidar.
                </p>
              </div>

              <div v-if="campaign.id == 'pacote-institucional'">
                <strong>Comunicador conversa com o público:</strong> <br />
                <p>
                  Gente, vocês têm acompanhado por aqui e também pelas ruas do Distrito Federal.
                  Mesmo com todas as dificuldades que tivemos nos últimos anos, o GDF não parou de
                  trabalhar nenhum dia.
                </p>
                <p>
                  Nesse tempo, o GDF colocou em andamento obras que melhoram as cidades e dão novos
                  empregos para os trabalhadores. Você pode ver por aí também escolas novinhas para
                  as crianças, tesourinhas reformadas e escolas técnicas para ajudar as pessoas a
                  conseguir um novo trabalho.
                </p>
                <p>
                  Também cuidou da população ao investir em saúde, construindo novas UPAs e UBSs e
                  contratando mais de cinco mil profissionais de saúde. Junto de tudo isso, criou o
                  maior programa de proteção alimentar do Brasil, o Cartão Prato Cheio, que acaba de
                  ser ampliado para atender 100 mil famílias.
                </p>
                <p>
                  Mas, pessoal, o melhor mesmo começa agora. O Túnel de Taguatinga vai ser
                  inaugurado muito em breve para melhorar a vida de toda a região. Ao longo deste
                  ano, ainda tem muitas novas entregas e muitas conclusões de obras.
                </p>
                <p>E aí você vai ver: para o DF, o melhor começa agora.</p>
              </div>

              <div v-if="campaign.id == 'pacote-institucional-reforco'">
                <strong>Comunicador conversa com o público:</strong> <br />
                <p class="mt-4">
                  Agora, vamos falar de notícia boa, principalmente pra quem não gosta de perder
                  tempo em engarrafamento.
                </p>
                <p>
                  Quem passa pelo centro de Taguatinga já deve ter percebido que o trânsito melhorou
                  muito! Diariamente, 140 mil veículos passam por ali. Muita gente reclamava que
                  levava um tempão só naquele trecho. Era um problemão!
                </p>
                <p>
                  Ainda bem que o GDF não para de trabalhar e vem colocando em andamento obras que
                  melhoram as cidades. Uma delas foi o novo Túnel Rei Pelé. Depois de inaugurado, o
                  trânsito ficou muito melhor!
                </p>
                <p>
                  Agora é muito menos tempo pra cruzar o centro de Taguatinga e ainda tá mais fácil
                  pra quem quer chegar no comércio.
                </p>
                <p>
                  São seis faixas pra acabar com os engarrafamentos e melhorar a qualidade de vida
                  de quem passa por lá, estando de carro ou de ônibus!
                </p>
                <p>
                  As pessoas que vivem em Taguatinga, Samambaia, Ceilândia, Pôr do Sol/Sol Nascente
                  agora têm mais tempo, tranquilidade e segurança. Esse novo Túnel Rei Pelé foi um
                  golaço de letra do GDF e merece ser comemorado! Para Taguatinga e todo o DF, o
                  melhor começa agora.
                </p>
              </div>

              <div v-if="campaign.id == 'imposto'">
                <strong>LOCUTOR (FALA DATA E HORÁRIO):</strong> <br />
                <p class="mt-4">
                  “Gente, aqui no DF para onde a gente olha tem uma obra, né mesmo? Mas muita gente
                  ainda não sabe que o túnel, os viadutos, as escolas, as UPAs, os hospitais, os
                  restaurantes comunitários, enfim, as obras que o GDF executa só são possíveis
                  porque são bancadas pelos impostos que nós pagamos. O meu, o seu, o dos
                  comerciantes e o dos empresários. Esse dinheiro financia até os programas sociais
                  do governo, que beneficiam mais de 1 milhão de pessoas em todo o Distrito Federal.
                </p>
                <p>
                  Por isso, é superimportante que eles sejam pagos em dia. E, também, que a gente
                  peça sempre o cupom fiscal de cada compra que a gente faz.
                </p>
                <p>
                  Aqui no DF, inclusive, pedir a nota fiscal nos dá a oportunidade de ganhar prêmios
                  em dinheiro vivo, que são distribuídos pelo Programa Nota Legal. Outro dia um
                  felizardo ganhou 500 mil reais! E só porque pediu a nota fiscal.
                </p>
                <p>
                  Então, ajude a fazer mais pelas nossas cidades. Porque, como diz a propaganda,
                  quando a gente contribui, o GDF retribui...”
                </p>
              </div>

              <div v-if="campaign.id == 'impostos'">
                <strong>LOCUTOR (FALA DATA E HORÁRIO):</strong> <br />
                <p class="mt-4">
                  “Gente, aqui no DF para onde a gente olha tem uma obra, né mesmo? Mas muita gente
                  ainda não sabe que o túnel, os viadutos, as escolas, as UPAs, os hospitais, os
                  restaurantes comunitários, enfim, as obras que o GDF executa só são possíveis
                  porque são bancadas pelos impostos que nós pagamos. O meu, o seu, o dos
                  comerciantes e o dos empresários. Esse dinheiro financia até os programas sociais
                  do governo, que beneficiam mais de 1 milhão de pessoas em todo o Distrito Federal.
                </p>
                <p>
                  Por isso, é superimportante que eles sejam pagos em dia. E, também, que a gente
                  peça sempre o cupom fiscal de cada compra que a gente faz.
                </p>
                <p>
                  Aqui no DF, inclusive, pedir a nota fiscal nos dá a oportunidade de ganhar prêmios
                  em dinheiro vivo, que são distribuídos pelo Programa Nota Legal. Outro dia um
                  felizardo ganhou 500 mil reais! E só porque pediu a nota fiscal.
                </p>
                <p>
                  Então, ajude a fazer mais pelas nossas cidades. Porque, como diz a propaganda,
                  quando a gente contribui, o GDF retribui...”
                </p>
              </div>

              <div v-if="campaign.id == 'balanco-institucional-2023'">
                <strong>
                  Apresentador fala para seu público, contando as novidades que o GDF traz.
                </strong>
                <br />
                <p class="mt-4">
                  Gente, nem sempre você vê notícias boas como essas na TV. Já reparou o tanto de
                  novidade do GDF por aí? Tem o novo Boulevard de Taguatinga. O centro da cidade
                  mais bonito, organizado e valorizado! A Avenida Paranoá também foi renovada. O
                  Hospital de Base ganhou novos leitos e a cozinha foi toda reformada.
                </p>
                <p>
                  Você não vê, mas embaixo da terra as obras do Projeto Drenar seguem firme na Asa
                  Norte. O Parque Octogonal tem a primeira a pista de skate park do DF. O Parque Sul
                  de Águas Claras tem a primeira pista de patinação de alta velocidade do país!
                </p>
                <p>
                  Quer mais? 59 ônibus escolares foram entregues, 11 creches inauguradas e 17 estão
                  em construção. E vem mais por aí: a Escola Técnica do Paranoá e a ampliação do
                  metrô em Samambaia.
                </p>
                <p>
                  Pessoal, o GDF pede desculpas pelo transtorno, mas já são mais de 6.200 obras. É
                  trabalho que você vê e melhorias que você sente.
                </p>
              </div>

              <div v-if="campaign.id == '2023-11-combate-ao-feminicidio-2'">
                <strong> Violência contra a Mulher </strong>
                <br />
                <p class="mt-4">
                  Atenção, pessoal! Vamos falar de um assunto bem sério agora. No dia 25 de novembro
                  é Dia Internacional de Luta pelo fim da Violência contra a Mulher. Um problema
                  grave que precisa ser combatido. E o DF está fazendo a parte dele.
                </p>
                <p class="mt-4">
                  Uma nova lei foi aprovada aqui no DF e prevê multa de até 500 mil reais para
                  agressores de mulher. É isso mesmo. Multa de até 500 mil reais! Além de serem
                  presos, os covardes também vão sentir no bolso.
                </p>
                <p class="mt-4">
                  E o Programa Mulher Mais Segura tem mais de 50 medidas de proteção. A violência
                  contra a mulher é crime. E os covardes não ficarão impunes. Quem ainda é
                  insensível ao sofrimento que causa a uma mulher vai para a prisão e vai sentir no
                  bolso.
                </p>
                <p class="mt-4">
                  Esse tipo de crime precisa acabar. Sabia que todos podem denunciar? Vamos todos
                  ficar atentos e ajudar a proteger as mulheres.
                </p>
                <p class="mt-4">Para denunciar qualquer agressão: disque 190.</p>
                <p class="mt-4">Governo do Distrito Federal</p>
              </div>

              <div v-if="campaign.id == 'restaurantes-comunitarios'">
                <strong> Locução </strong>
                <br />
                <p class="mt-4">
                  Me diga aí, meu amigo. Me diga aí, minha amiga? O que dá pra fazer com um real,
                  hoje em dia?
                </p>
                <p class="mt-4">
                  Pois, quem mora ou trabalha em Arniqueiras já pode investir suas moedinhas em uma
                  refeição gostosa, nutritiva e balanceada que já está sendo servida no novo
                  restaurante comunitário que o GDF inaugurou na cidade.
                </p>
                <p class="mt-4">
                  O restaurante de Arniqueiras é o décimo sexto a entrar em funcionamento, aqui no
                  DF. E não é só almoço, não. Lá, você também pode tomar o café-da-manhã e
                  aproveitar o jantar, pagando, apenas, cinquenta centavos! É barato demais, né não?
                </p>
                <p class="mt-4">
                  É muito bacana a gente ver a preocupação do GDF em levar alimentação de qualidade
                  para as pessoas que mais precisam, vocês não acham? Valeu, GDF...
                </p>
              </div>

              <div v-if="campaign.id == '12-2023-balanco-institucional-2023'">
                <strong> LOCUTOR: FALA DATA E HORÁRIO </strong>
                <br />
                <p class="mt-4">
                  Vocês já devem ter reparado que o DF inteiro está se transformando... Um caso
                  emblemático é Vicente Pires, antes alagava tudo e agora está quase toda com
                  drenagem....
                </p>
                <p class="mt-4">
                  Mas ainda tem um lugar que está se transformando mais ainda... Vou dar uma dica:
                  ele está deixando de ser favela e está virando uma cidade de verdade. É claro que
                  eu estou falando do Sol Nascente e do Pôr do Sol...
                </p>
                <p class="mt-4">
                  Imagina, depois de quase vinte anos de abandono, largado à própria sorte, o Sol
                  Nascente hoje tem escolas, tem UBS, tem restaurante comunitário e tem até
                  rodoviária! O GDF está investindo mais de seiscentos milhões de reais ali e está
                  fazendo de tudo... pavimentando ruas, colocando rede de esgoto, água, luz, fazendo
                  drenagem... Transformação real para as cento e cinquenta mil pessoas que moram lá!
                </p>
                <p class="mt-4">
                  O trecho um e dois ficaram prontos e agora as obras são no trecho três. Aliás,
                  pessoal do trecho três, um pouquinho de paciência, ainda mais com as chuvas... Tem
                  sempre um pessoal que fica botando pilha errada... mas vamos olhar o copo cheio
                  minha gente....
                </p>
                <p class="mt-4">
                  Nunca fizeram tanto pelo Sol Nascente! Vou te dizer: o Sol Nascente ERA a maior
                  favela do Brasil. Hoje, está virando uma cidade de verdade! GDF.
                </p>
              </div>

              <div
                v-if="campaign.id == '12-2023-novo-programa-de-aceleracao-do-crescimento-pac-sul'"
              >
                <h4 class="my-6" :style="{ color: companiesDict[campaign.ownerId].color }">
                  CONTEÚDO DEZEMBRO 2023
                </h4>
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Paraná </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou ao Paraná?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, a do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento para o nosso
                    estado.
                  </p>
                  <p>Mas aí você me pergunta: como? E eu te respondo na hora!</p>
                  <p>
                    Aqui, o Novo PAC traz melhorias para as estradas com a concessão de 6 lotes de
                    rodovias. São mais de 3.300 km. Tem também a construção da BR-487, Boiadeira,
                    que liga Serra dos Dourados a Cruzeiro do Oeste, e também a Construção do
                    Contorno Leste de Guaíra, na BR-163.
                  </p>
                  <p>
                    Mas não para por aí, né? O Novo PAC está entregando milhares de moradias do
                    Minha Casa Minha Vida. E gerando inclusão com internet de qualidade em mais de 7
                    mil escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Paraná? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil
                    no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Rio Grande do Sul </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou ao Rio Grande do Sul?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, a do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento para o nosso
                    estado.
                  </p>
                  <p>Mas aí você me pergunta: como? E eu te respondo na hora!</p>
                  <p>
                    Aqui, o Novo PAC gera emprego e mais segurança nas estradas com a duplicação da
                    BR-116 e a construção de acessos à Nova Ponte do Guaíba.
                  </p>
                  <p>
                    Mas as obras não param por aí! Tem ainda a duplicação da BR-290, que liga
                    Eldorado do Sul a Pântano Grande. E o Projeto de prolongamento da BR-448 até
                    Portão - Rodovia do Parque.
                  </p>
                  <p>
                    São muitos benefícios, né? Inclusive na saúde, com ampliação do Hospital
                    Conceição de Porto Alegre e dos Hospitais Universitários de Santa Maria e de
                    Pelotas.
                  </p>
                  <p>
                    E milhares de moradias do Minha Casa Minha Vida. Quer saber mais o que o Novo
                    PAC está realizando no Brasil e no Rio Grande do Sul? Acesse o site
                    gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil no
                    rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Santa Catarina </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou a Santa Catarina?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, a do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento para o nosso
                    estado.
                  </p>
                  <p>Mas aí você me pergunta: como? E eu te respondo na hora!</p>
                  <p>
                    Com a duplicação das rodovias BR-470 e BR-280, o Novo PAC chega ampliando a
                    mobilidade e a eficiência das estradas. Mas não para por aí. Tem ainda o aumento
                    da capacidade e segurança da BR-282, que vai do Trecho Chapecó a São Miguel do
                    Oeste.
                  </p>
                  <p>
                    Calma, que tem mais benefícios. Aqui, o coração catarinense vai bater ainda
                    melhor com a nova sede do Instituto de Cardiologia, além de milhares de moradias
                    do Minha Casa Minha Vida. E internet em 5.196 escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e em Santa Catarina?
                    Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é
                    o Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <h4 class="my-6" :style="{ color: companiesDict[campaign.ownerId].color }">
                  CONTEÚDO NOVEMBRO 2023
                </h4>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Paraná </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC já chegou no Paraná? E que ele
                    vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia da sua
                    cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui o Novo PAC traz melhorias para as estradas com a concessão de 6 lotes de
                    rodovias. São mais de 3.300 km. Tem também a construção da BR- 487, Boiadeira,
                    que liga Serra Dos Dourados a Cruzeiro do Oeste, e também a Construção do
                    Contorno Leste de Guaíra, na BR 163.
                  </p>
                  <p>
                    Mas não para por aí, ne? O Novo PAC está entregando milhares de moradias do
                    Minha Casa Minha vida, e gerando inclusão com internet de qualidade em mais de 7
                    mil escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Paraná? Acesse o site
                    gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Paraná no
                    rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Rio Grande do Sul </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC já chegou no Rio Grande do
                    Sul? E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a
                    economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui, o Novo PAC gera emprego e mais segurança nas estradas com a duplicação da
                    BR-116 e a construção de acesso à Nova Ponte do Guaíba.
                  </p>
                  <p>
                    Mas as obras não param por aí! Tem ainda a duplicação da BR-290, que liga
                    Eldorado Sul a Pântano Grande. São muitos benefícios, né? Inclusive na saúde,
                    com a ampliação dos Hospitais Universitários de Santa Maria e de Pelotas. E
                    milhares de moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Rio Grande do Sul? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Rio
                    Grande do Sul no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4">
                  <p>
                    <strong> Santa Catarina </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC já chegou em Santa Catarina? E
                    que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia da
                    sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Com a duplicação das rodovias BR 470 e BR-280, o Novo Pac chega ampliando a
                    mobilidade e eficiência das estradas. Mas não para por aí, tem ainda o aumento
                    da capacidade e segurança da BR 282 que vai do Trecho Chapecó a São Miguel do
                    Oeste.
                  </p>
                  <p>
                    Calma, que tem mais benefícios. Aqui o coração catarinense vai bater ainda
                    melhor com a nova sede do Instituto de Cardiologia, além de milhares de moradias
                    do Minha Casa Minha Vida. E internet em 5.196 escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo Pac está realizando em Santa Catarina? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é Santa
                    Catarina no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
              </div>

              <div
                v-if="campaign.id == '12-2023-novo-programa-de-aceleracao-do-crescimento-pac-norte'"
              >
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Acre </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou no Acre?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante moradia, trabalho e desenvolvimento
                    pro nosso estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Aqui o Novo PAC prioriza a saúde dos acreanos, com a
                    Nova Maternidade de Rio Branco, a ampliação do Hospital de Urgência e Emergência
                    Pediátrica, além do Novo Hospital Universitário de Rio Branco.
                  </p>
                  <p>
                    Mas os benefícios não param por aí, viu? Tem ainda melhorias no transporte com a
                    restauração da BR-364 e a construção da ponte sobre o Rio Juruá, além de novas
                    moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Acre? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil
                    no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Amazonas </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou no Amazonas?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e transporte eficiente pro
                    nosso estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Aqui o Novo PAC vai gerar mais segurança e melhorias
                    para o transporte com a restauração da BR-174 e a ampliação do Terminal de
                    Manaus Moderna.
                  </p>
                  <p>
                    Mas os benefícios não param por aí, viu! Tem a entrega de 15 novos portos
                    hidroviários e 4 novos aeroportos em Coari, Fonte Boa, Parintins e São Gabriel
                    da Cachoeira. Tem também milhares de moradias do Minha Casa Minha Vida, além de
                    internet para mais de 5 mil escolas e novos leitos de UTI.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Amazonas? Acesse
                    o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o
                    Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Amapá </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou no Amapá?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante desenvolvimento, renda e inclusão pro
                    nosso estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Aqui no Amapá as obras do Novo PAC vão gerar milhares
                    de empregos e melhorias com a construção da BR-156, Norte-Sul, além do Novo
                    terminal de passageiros de Santana.
                  </p>
                  <p>
                    Mas os benefícios para os amapaenses não param por aí. Tem ainda Luz para Todos
                    para mais de 19 mil famílias, internet em mais de 700 escolas e milhares de
                    moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Amapá? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil
                    no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Pará </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou no Pará?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego, desenvolvimento e melhoria de
                    vida pro nosso estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Aqui o Novo PAC traz melhorias para o transporte com a
                    construção da ponte sobre o Rio Xingu, na BR 230, e a duplicação da BR-316,
                    entre Castanhal e o Trevo de Salinas, além da pavimentação da BR-308, entre
                    Viseu e Bragança.
                  </p>
                  <p>
                    Mas os benefícios não param por aí, viu? Tem ainda a ampliação do Hospital
                    Universitário de Belém, internet para mais de 9 mil e 700 escolas e milhares de
                    moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Pará? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil
                    no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Rondônia </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou em Rondônia?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso
                    estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Aqui as obras do Novo PAC vão gerar mais oportunidades
                    e economia, com a construção da Ponte Internacional de Guajará-Mirim, além da
                    Travessia urbana de Ji-Paraná e Jaru.
                  </p>
                  <p>
                    Mas os benefícios não param por aí! Tem ainda milhares de moradias do Minha Casa
                    Minha Vida, internet para mais de mil escolas e Luz Para Todos para mais de 12
                    mil famílias rondonienses.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e em Rondônia? Acesse
                    o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o
                    Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Roraima </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou em Roraima?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso
                    estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Aqui O Novo PAC vai trazer mais segurança na estrada
                    com a restauração da BR-174 e melhorias na saúde com a ampliação do Hospital
                    Geral e do Hospital Materno-Infantil de Roraima e o novo Hospital Universitário
                    de Boa Vista, ampliando o cuidado com a nossa gente.
                  </p>
                  <p>
                    Mas os benefícios para o roraimense não param por aí, viu? Tem ainda inclusão
                    digital com internet em mais de 800 escolas e a construção de milhares de
                    moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e em Roraima? Acesse
                    o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o
                    Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Tocantins </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou no Tocantins?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso
                    estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Aqui o Novo PAC vai entregar a ponte sobre o Rio
                    Araguaia-Xambioá, gerando mais mobilidade e economia.Mas os benefícios não param
                    por aí! Tem melhoria na saúde com o Novo Hospital da Mulher e Maternidade em
                    Araguatins.
                  </p>
                  <p>
                    E, também nas rodovias com a pavimentação da BR-010, entre Paranã e a Divisa de
                    Goiás, e da BR-235, entre Pedro Afonso e a Divisa Maranhão/Tocantins, além de
                    milhares de moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Tocantins?
                    Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é
                    o Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
              </div>

              <div
                v-if="
                  campaign.id ==
                  '12-2023-novo-programa-de-aceleracao-do-crescimento-pac-centro-oeste'
                "
              >
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Distrito Federal </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou no Distrito Federal?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso
                    estado. Mas aí você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    O Novo PAC está fazendo muito pelo DF, com a implantação do tão sonhado BRT
                    Norte - Plano Piloto a Planaltina e do BRT Eixo Sul. Mas não por aí, ainda tem
                    duas novas estações do metrô em Ceilândia, e aumento na capacidade e segurança
                    da BR-080.
                  </p>
                  <p>
                    São muitos benefícios. Inclusive, milhares de moradias do Minha Casa Minha Vida
                    e inclusão digital com internet em mais de 700 escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Distrito
                    Federal? Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O
                    Novo PAC é o Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Goiás </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou em Goiás?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso
                    estado. Mas aí você me pergunta: como?
                  </p>
                  <p>
                    E eu te respondo na hora! Pra começar, tem milhares de moradias do Minha Casa
                    Minha Vida. E mais saúde para os goianos, com a construção do Hospital do Câncer
                    de Goiás. Mas não para por aí. Aqui o Novo PAC gera mais mobilidade e qualidade
                    de vida por meio do BRT que liga Luziânia a Brasília.
                  </p>
                  <p>
                    São muitos benefícios, né? E tem mais chegando, como a construção da ponte sobre
                    o rio Araguaia e da BR-080 e da Ferrovia de Integração do Centro-Oeste - FICO.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e em Goiás? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil
                    no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Mato Grosso do Sul </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou em Mato Grosso do Sul?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego, inclusão e desenvolvimento
                    pro nosso estado. Mas aí você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Com a Construção do Contorno de Três Lagoas e o aumento da capacidade e
                    segurança da BR -267, entre Alto Caracol e Porto Murtinho, é mais gente
                    trabalhando e garantindo melhorias nas estradas.
                  </p>
                  <p>
                    Mas não para por aí. Tem ainda ampliação do Aeroporto de Dourados, e mais
                    cuidado com a saúde com a ampliação dos Hospitais Universitários de Campo Grande
                    e da Grande Dourados. Além de internet para mais de 1.300 escolas e milhares de
                    moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e em Mato Grosso do
                    Sul? Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo
                    PAC é o Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Mato Grosso </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou em Mato Grosso?
                  </p>
                  <p>
                    E que ele vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia
                    da sua cidade? Pois é, o Novo PAC garante emprego e melhoria de vida pro nosso
                    estado. Mas aí você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui o Novo PAC chega ampliando as rodovias do estado, com a construção da BR
                    158, Contorno da Terra Indígena, e da BR 242, entre Gaúcha do Norte e Santiago
                    do Norte, gerando mais capacidade e segurança de transporte.
                  </p>
                  <p>
                    Mas não para por aí, tem ainda a construção da Ferrovia de Integração
                    Centro-Oeste -FICO. São muitas melhorias! inclusive para a qualidade de vida,
                    com milhares de moradias do Minha Casa Minha Vida e internet em mais de 2 mil e
                    200 escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e em Mato Grosso?
                    Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é
                    o Brasil no rumo certo.
                  </p>
                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
              </div>

              <div v-if="campaign.id == 'novo-programa-de-aceleracao-do-crescimento-pac-nordeste'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Alagoas </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou em Alagoas? E que ele vai melhorar a
                    sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante água e emprego pro nosso estado. Mas aí você me
                    pergunta: como? E eu te respondo na hora.
                  </p>
                  <p>
                    Aqui o Novo PAC é melhoria de vida com o Canal do Sertão Alagoano - Trecho 5. E
                    também ampliação de rodovias, gerando mais agilidade e segurança no transporte,
                    com as obras de duplicação da BR-101 e duplicação do Arco Metropolitano de
                    Maceió.
                  </p>
                  <p>
                    Mas não para por aí! Tem ainda novas moradias do Minha Casa Minha Vida, e o Novo
                    Hospital Metropolitano de Arapiraca. Quer saber mais o que o Novo PAC está
                    realizando no Brasil e em Alagoas?
                  </p>
                  <p>
                    Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é
                    o Brasil no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Bahia </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou na Bahia? E que ele vai melhorar a
                    sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    O Novo PAC traz mais segurança e eficácia para o transporte com a duplicação da
                    BR-101, da Divisa Sergipe a Feira de Santana, da BR-116, entre Serrinha e Feira
                    de Santana, e também da BR- 242, entre Barreiras e Luís Eduardo Magalhães. Tem
                    também a Ferrovia de Integração Oeste-Leste - FIOL e o Contorno Norte de Feira
                    de Santana.
                  </p>
                  <p>
                    Mas não para por aí. Vai ter a ampliação do Hospital Universitário da UFBA, e
                    milhares de moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo Pac está realizando no Brasil e na Bahia? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil
                    no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Ceará </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou no Ceará? E que ele vai melhorar a
                    sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante desenvolvimento e emprego pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    O Novo PAC está trazendo mais qualidade de vida para o cearense com as obras de
                    duplicação do Eixão das Águas que vão melhorar a disponibilidade de água no
                    estado. Além da construção do Cinturão das Águas - Trecho I e o Ramal do
                    Salgado. Tem a Ferrovia Transnordestina e mais segurança nas rodovias com a
                    duplicação da BR-116, no trecho Pacajus-Boqueirão do Cesário-Tabuleiro do Norte.
                    E tem mais! Milhares de Moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Ceará? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber.O Novo PAC é o Brasil
                    no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Maranhão </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou no Maranhão? E que ele vai melhorar
                    a sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui o Novo PAC está trazendo mais qualidade de vida para os maranhenses, com a
                    Universalização do abastecimento de água em Barreirinhas, São Luís e Imperatriz.
                    Além de melhorias para o transporte com a duplicação da BR-010, entre Imperatriz
                    e Açailândia. Mas não para por aí! Tem ainda milhares de moradias do Minha Casa
                    Minha Vida, e inclusão digital com internet em mais de 10.400 escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Maranhão? Acesse
                    o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o
                    Brasil no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Pernambuco </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou no Pernambuco? E que ele vai
                    melhorar a sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante água no sertão e emprego pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui o Novo PAC está ampliando a distribuição de água com a Adutora do Pajeú e a
                    Adutora do Agreste Pernambucano. Mas não para por aí! Tem a Ferrovia
                    Transnordestina, e o aumento da capacidade e segurança da BR-423, entre São
                    Caetano e Lajedo, e da BR-104, entre Caruaru e Divisa Paraíba. Além da conclusão
                    da Refinaria Abreu e Lima, e milhares de moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Pernambuco?
                    Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é
                    o Pernambuco no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Paraíba </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país já chegou na Paraíba? E que ele vai melhorar a
                    sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante desenvolvimento e emprego pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    O Novo PAC está ampliando a distribuição de água por meio do canal Acauã-Araçagi
                    e das Adutoras de Pajeú, Brejo e Transparaíba. Tem a duplicação da BR-230, entre
                    Campina Grande e Farinha. E mais benefícios como o novo Hospital de Clínicas e
                    Traumatologia do Sertão Paraibano em Patos, a ampliação dos Hospitais
                    Universitários da Paraíba e de Campina Grande e Milhares de Moradias do Minha
                    Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e na Paraíba? Acesse
                    o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o
                    Brasil no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Piauí </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou no Piauí? E que ele vai melhorar a
                    sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante eficiência no transporte e milhares de empregos pro
                    nosso estado. Mas aí você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui o Novo PAC traz melhorias das rodovias, com a construção da BR-330, entre
                    Divisa Maranhão, o entroncamento da rodovia PI-392 e Ponte Ribeiro Gonçalves, e
                    a duplicação da BR-343, entre Teresina e Altos. Além da Barragem Nova Algodões e
                    a Adutora de Jaicós, ampliando e aperfeiçoando o abastecimento de água. Mas não
                    para por aí. Tem ainda a tão sonhada Ferrovia Transnordestina e milhares de
                    moradias do Minha Casa Minha Vida.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Piauí? Acesse o
                    site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o Brasil
                    no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Rio Grande do Norte </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou no Rio Grande do Norte? E que ele
                    vai melhorar a sua vida, a do seu vizinho, do seu filho e a economia da sua
                    cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui o Novo PAC traz melhorias de vida para o potiguar, ampliando o
                    abastecimento de água para toda a região, com o Ramal do Apodi, a Barragem
                    Oiticica e a Adutora do Seridó Norte. Mas não para por aí! Tem ainda melhorias
                    no transporte, com a duplicação da BR-304 - Reta Tabajara, o novo Hospital de
                    Urgências e Emergências em Trauma e Neurocirurgia de Parnamirim, e milhares de
                    moradias do Minha Casa Minha Vida. Esse é só o começo.
                  </p>
                  <p>
                    Quer saber mais o que o Novo PAC está realizando no Brasil e no Rio Grande do
                    Norte? Acesse o site gov.br/novopac. Lá tem tudo o que você precisa saber. O
                    Novo PAC é o Brasil no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
                <!-- // -->
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Sergipe </strong>
                  </p>
                  <p class="mb-4">Locução:</p>
                  <p>
                    E aí, pessoal? Vocês estão sabendo que o Novo PAC do Governo Federal, o maior
                    programa de investimentos do país, já chegou em Sergipe? E que ele vai melhorar
                    a sua vida, a do seu vizinho, do seu filho e a economia da sua cidade?
                  </p>
                  <p>
                    Pois é, o Novo PAC garante emprego e desenvolvimento pro nosso estado. Mas aí
                    você me pergunta: como? E eu te respondo na hora!
                  </p>
                  <p>
                    Aqui o novo Pac esta melhorando a segurança e eficiência do transporte com a
                    duplicação da BR 101 SUL E NORTE além de realizar obras que contribuiem com o
                    enriquecimento do ESTADO como a implantação do gasoduto SERGIPE AGUAS PROFUNDAS
                    e duas novas unidades de produção de petróleo e gas natural. Mas não para por
                    aí. Tem ainda milhares de moradias e internet em mais de 1600 escolas.
                  </p>
                  <p>
                    Quer saber mais o que o Novo Pac está realizando no Brasil e em SERGIPE? Acesse
                    o site gov.br/novopac. Lá tem tudo o que você precisa saber. O Novo PAC é o
                    Brasil no rumo certo.
                  </p>

                  <p>Brasil. União e reconstrução. Governo Federal.</p>
                </section>
              </div>

              <div v-if="campaign.id == '03-2024-cartao-material-escolar'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>APRESENTADOR:</strong>
                  </p>
                  <p>
                    Todo mundo sabe que as aulas da rede pública já começaram. Mas o que talvez você
                    não saiba é que aqui no DF, cento e cinquenta e cinco mil crianças de famílias
                    de baixa renda foram para as suas aulas com o material escolar novinho em folha!
                    Mochila, estojo, lápis, caderno...
                  </p>
                  <p>
                    E o mais importante é que elas puderam escolher o que elas queriam. Essa alegria
                    não tem preço mas tem nome: é o Cartão Material Escolar! A mãe e o pai que estão
                    no programa vão na loja com o filho, o filho escolhe e é só passar o cartão.
                  </p>
                  <p>
                    E esse é um benefício que está servindo de exemplo para o resto do país, sabia?
                    É isso mesmo... e o Cartão Material Escolar dá dignidade para as crianças que
                    mais precisam e ainda movimenta a economia ajudando a manter as papelarias e
                    pequenos negócios cadastrados em uma região pertinho de você!
                  </p>
                  <p>
                    Cartão Material Escolar, material garantido para crianças de famílias de baixa
                    renda e é do Governo do Distrito Federal.
                  </p>
                </section>
              </div>

              <div v-if="campaign.id == '04-2024-aniversario-de-brasilia'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>
                      Apresentador fala em tom de conversa com o espectador. Mas a ideia é que o tom
                      seja de emoção e de empolgação:
                    </strong>
                  </p>
                  <p>Pessoal, agora eu vou fazer uma homenagem, em meu nome e em nome do GDF.</p>
                  <p>
                    Brasília tá fazendo 64 anos. É isso aí, 21 de abril é o aniversário da cidade e
                    nós temos motivos pra comemorar. Porque é aqui que a gente vive e onde a gente
                    pode crescer e batalhar pra levar uma vida melhor. Nossa cidade é mesmo
                    espetacular.
                  </p>
                  <p>
                    E sabe quem concorda? O jornal americano New York Times, um dos mais importantes
                    do mundo. Recentemente, o jornal destacou Brasília como um dos destinos para se
                    visitar no mundo inteiro. Foi a única cidade brasileira entre cinquenta e dois
                    lugares escolhidos.
                  </p>
                  <p>
                    E nós concordamos com isso, né? E o GDF tá trabalhando para que fique um lugar
                    cada vez melhor, tanto para se visitar quanto para se viver. Com obras de
                    infraestrutura que a cidade precisava há anos, com programas sociais que trazem
                    ajuda a quem mais precisa e a criação de oportunidades de emprego.
                  </p>
                  <p>Então, parabéns! Viva Brasília, o nosso melhor presente.</p>
                </section>
              </div>

              <div v-if="campaign.id == '04-2024-multivacinacao-2024'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Locução: </strong>
                  </p>
                  <p>
                    Gente, não sei se vocês estão sabendo, mas, os casos de Covid estão aumentando
                    em todo o Brasil. Por incrível que pareça, muita gente está relaxando com a
                    vacinação.
                  </p>
                  <p>
                    Agora, chegou a hora de se vacinar contra a gripe. Então, essa é uma ótima
                    oportunidade para você colocar em dia a sua vacinação.
                  </p>
                  <p>
                    As Unidades de Saúde do GDF oferecem vacinas contra um grande número de doenças.
                    É tudo de graça.
                  </p>
                  <p>Para saber mais, acesse saude.df.gov.br/vacina</p>
                  <p>Eu vou repetir: saude.df.gov.br/vacina</p>
                  <p>Tudo que você precisa saber você vai encontrar neste site, ok?</p>
                  <p>
                    Mas, o mais importante é você se vacinar. Em um único dia dá pra tomar as duas
                    vacinas: gripe e Covid. Então... Tá esperando o quê?
                  </p>
                </section>
              </div>

              <div v-if="campaign.id == '07-2024-bilhetagem-onibus'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>
                      Apresentador, em tom coloquial, conversa com seu público e fala sobre as
                      mudanças nas linhas de ônibus::
                    </strong>
                  </p>
                  <p>
                    Gente, agora vou dar uma informação muito importante do GDF pra quem anda de
                    ônibus. Você já deve ter reparado: até no comércio, se usa cada vez menos
                    dinheiro em notas ou moedas.
                  </p>
                  <p>
                    Então, esse mês já começou uma mudança gradual no transporte público. É o
                    seguinte: aos poucos, os ônibus vão deixar de receber dinheiro em espécie,
                    passando a aceitar somente os meios eletrônicos, como cartões de débito, crédito
                    ou o Cartão Mobilidade.
                  </p>
                  <p>
                    A alteração já aconteceu em 52 linhas e, depois de um tempo, vai valer para
                    todos os ônibus. Aquele processo de passar na catraca vai ficar mais rápido e
                    prático.
                  </p>
                  <p>
                    E sabe como retirar o seu Cartão Mobilidade? É só passar nos postos credenciados
                    do Na Hora ou na rodoviária da sua cidade. Com ele, você faz a integração: com
                    uma passagem, faz até três viagens, no mesmo sentido, em até três horas.
                  </p>
                  <p>Para mais informações, acesse mobilidade.brb.com.br.</p>
                  <p>É, dinheiro não vai mais andar de ônibus.</p>
                  <p>Esse é o recado do GDF pra você.</p>
                </section>
              </div>

              <div v-if="campaign.id == '07-2024-terceira-faixa-de-planaltina'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Locução: </strong>
                  </p>
                  <p>
                    “Olá! Hoje eu tenho um recado para você, motorista, que tem o hábito de utilizar
                    a BR-020. O GDF já iniciou as obras da terceira faixa no trecho que liga
                    Sobradinho a Planaltina
                  </p>
                  <p>
                    Como a segurança do cidadão é uma das grandes preocupações da atual gestão do
                    GDF, essa obra se tornou fundamental, já que esse trecho tem os maiores índices
                    de acidentes graves aqui no Distrito Federal.
                  </p>
                  <p>
                    Ah! O GDF pede desculpas pelo transtorno que essa obra vai causar, mas, depois
                    de pronta, a terceira faixa que liga Sobradinho a Planaltina vai facilitar
                    demais a vida da população daquela região. Então redobre sua atenção quando
                    passar pelo local.
                  </p>
                  <p>
                    Além da segurança, essa obra vai encurtar distâncias, para que você chegue mais
                    rápido em casa e ganhe mais tempo para curtir os amigos e a família.
                  </p>
                  <p>E isso é bom demais, não é mesmo?”</p>
                </section>
              </div>

              <div v-if="campaign.id == '07-2024-entrega-de-medicamentos'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Locutor em tom de conversa. </strong>
                  </p>
                  <p>
                    Pessoal, tem muita coisa boa que está acontecendo na saúde e às vezes a gente
                    nem fica sabendo. Vocês sabiam que este GDF tem um programa que leva o remédio
                    de alto custo, de graça, na casa de quem precisa?
                  </p>
                  <p>
                    Esse foi um programa que este GDF criou na época da pandemia e deu tão certo que
                    o GDF não parou mais. É o Programa Remédio em Casa. Mais de dez mil entregas são
                    feitas todos os meses.
                  </p>
                  <p>
                    Uma mão na roda! Ajuda quem faz uso contínuo das medicações, ajuda quem tem
                    dificuldade de locomoção, ajuda quem trabalha e não pode ir à farmácia de alto
                    custo, ajuda quem está em tratamento.
                  </p>
                  <p>
                    E vai tudo certinho, com o dia agendado! Para você saber como se cadastrar no
                    Programa Remédio em Casa do GDF, é só ligar um meia zero, opção três ou acesse o
                    site saude.df.gov.br/componente-especializado.
                  </p>
                  <p>Remédio em casa, foi este GDF que fez. E está fazendo muito mais.</p>
                </section>
              </div>

              <div v-if="campaign.id == '08-2024-morar-df'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong> Locutor: </strong>
                  </p>
                  <p>
                    Agora eu quero falar com você, que sonha em morar em um cantinho só seu, receber
                    a família em casa no domingo, sair do aluguel… Mas que, na hora de dar a entrada
                    no financiamento, fica apertado, né?
                  </p>
                  <p>
                    É… não é fácil mesmo… Sabia que 70% das pessoas que tentam financiar a casa
                    própria acabam desistindo quando encaram a parcela de entrada
                  </p>
                  <p>Mas pode ficar tranquilo, meu amigo, minha amiga… porque agora dá!</p>
                  <p>
                    O GDF criou o Passaporte Morar DF. Com ele, famílias que recebem até cinco
                    salários mínimos por mês podem receber até 15 mil reais para dar entrada no
                    sonho da casa própria, pessoal!
                  </p>
                  <p>
                    Isso mesmo que você escutou: até 15 mil reais para dar entrada no financiamento
                    da sua casa, organizar as finanças e sair do aluguel pra um lugar só seu. Ou
                    seja, agora dá!
                  </p>
                  <p>
                    Para ter mais informações e se inscrever no programa, é só acessar
                    df.gov.br/passaportemorardf
                  </p>
                  <p>Acesse aí que, com essa ajudinha do GDF, agora dá!</p>
                </section>
              </div>

              <div v-if="campaign.id == '09-2024-balanco-institucional-2024-saude'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor em tom amigável e de conversa</strong>
                  </p>
                  <p>
                    Pessoal, para tudo o que vocês estão fazendo! Porque agora eu trago uma notícia
                    que está movimentando a saúde do DF.
                  </p>
                  <p>
                    Imagina várias carretas circulando pelas cidades pra fazer atendimento médico
                    perto de você. Elas são verdadeiros consultórios móveis com equipamentos e toda
                    estrutura que você precisa para se consultar e fazer vários exames.
                  </p>
                  <p>
                    Tem exame de sangue, exame de vista, mamografia, ultrassonografia,
                    eletrocardiograma.... Gente, ao todo são catorze especialidades médicas: tem
                    cardiologia, psicologia, nutrição, saúde da mulher, saúde do homem e muito mais.
                  </p>
                  <p>
                    As carretas vão em comboio para uma das cidades daqui do DF e basta você ir lá,
                    fazer sua ficha, passar pela triagem e ser encaminhada para as consultas e para
                    os exames. Notícia boa, né?
                  </p>
                  <p>
                    É a saúde mais perto do cidadão e mais perto de você! É este GDF em movimento
                    pela sua saúde.
                  </p>
                </section>
              </div>

              <div v-if="campaign.id == '10-2024-dengue-2024-fase-ii'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor conversa com seu público em tom sério.</strong>
                  </p>
                  <p>
                    Gente, vamos lembrar de um tema muito importante. Na última temporada de chuvas,
                    os casos de dengue aumentaram muito em todo o Brasil, e no Distrito Federal não
                    foi diferente.
                  </p>
                  <p>
                    Você deve ter visto nos noticiários. Mas o que muita gente não sabe é que a
                    prevenção pode e deve começar agora. As chuvas não podem trazer a dengue de
                    volta.
                  </p>
                  <p>
                    Você já sabe o que fazer, mas não custa lembrar. Os pratinhos de planta devem
                    estar com areia, as garrafas precisam estar viradas pra baixo e a caixa d’água
                    deve estar tampada. O lixo tem que estar bem fechado em uma lixeira com tampa e
                    o quintal limpo livre de entulhos.
                  </p>
                  <p>
                    Aproveite agora e olhe no seu quintal, na sua varanda, no seu jardim, se não tem
                    nada que possa acumular água e virar criadouro do mosquito. Uma tampinha de
                    garrafa pode se tornar um criadouro.
                  </p>
                  <p>
                    A hora de prevenir a dengue é agora. Comece com a sua casa e a sua vizinhança.
                  </p>
                  <p>GDF</p>
                </section>
              </div>

              <div v-if="campaign.id == '10-2024-campanha-comportamento-seguro-chuvas-2024'">
                <section class="ml-4 mb-12">
                  <p>
                    Até que enfim a época de chuvas chegou! E você sabe, né? Chuva aqui em Brasília
                    é sempre um acontecimento. A galera tira foto, filma, posta nas redes sociais,
                    manda nos grupos... É uma festa!
                  </p>
                  <p>
                    Mas quando o assunto é chuva no trânsito, muita atenção pra essa festa não ir
                    por água abaixo e se transformar numa dor de cabeça das grandes.
                  </p>
                  <p>
                    Então, se liga nas dicas que o Detran-DF e o GDF têm pra você: mantenha os pneus
                    e limpadores de para-brisas sempre em bom estado, use faróis baixos, reduza a
                    velocidade, fique a uma distância segura do veículo da frente e não freie
                    bruscamente em caso de aquaplanagem.
                  </p>
                  <p>
                    Tá entendido? Tem que ficar ligado. A gente passa um tempão sem chuva, com
                    aquele calorão, uma secura danada. Aí quando começa a chover, que a gente
                    poderia ficar mais tranquilo, começam os acidentes em tudo que é canto. Assim
                    não dá, né?
                  </p>
                  <p>A regra é clara: pista molhada, atenção redobrada.</p>
                  <p>Paz no trânsito começa por você.</p>
                </section>
              </div>

              <div v-if="campaign.id == '11-2024-posicionamento-de-governo-fe-no-brasil-regional'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor:</strong>
                  </p>
                  <p>
                    O Governo Federal trabalha em parceria com o estado e com os municípios para São
                    Paulo se desenvolver cada dia mais.
                  </p>
                  <p>
                    Recursos federais vão tornar realidade obras há muito tempo esperadas, como o
                    túnel Santos-Guarujá, para melhorar a vida da população e ampliar a capacidade
                    de operação do Porto de Santos.
                  </p>
                  <p>
                    Com recursos do BNDES, as obras do Rodoanel Norte vão avançar e o trem
                    intercidades, de Campinas a São Paulo, vai sair do papel.
                  </p>
                  <p>
                    O financiamento do BNDES também vai viabilizar as obras da Rio-Santos e da Via
                    Dutra.
                  </p>
                  <p>Na capital, serão ampliadas as linhas Verde e Lilás do Metrô.</p>
                  <p>
                    Com o PAC Seleções, 320 municípios vão ser beneficiados com 1.456 obras e ações
                    nas áreas de saúde, educação e infraestrutura urbana.
                  </p>
                  <p>
                    Espera, que não para por aí: São Paulo vai ganhar mais seis policlínicas, um
                    novo Hospital da UNIFESP e 12 novos campi de institutos federais.
                  </p>
                  <p>
                    E ainda tem as mais de 294 mil famílias que já receberam a casa própria do Minha
                    Casa, Minha Vida.
                  </p>
                  <p>
                    É coisa que não acaba mais! É o Governo Federal investindo em São Paulo para
                    você viver melhor. Fé no Brasil. A gente tá no rumo certo.
                  </p>
                </section>
              </div>

              <div v-if="campaign.id == '11-2024-dengue-2024-fase-ii'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor conversa com seu público em tom sério.</strong>
                  </p>
                  <p>Gente, assunto da maior importância!</p>
                  <p>
                    Na última temporada de chuvas, os casos de dengue aumentaram muito em todo o
                    Brasil, e no Distrito Federal não foi diferente. Você deve ter visto nos
                    noticiários. Por isso que o combate à dengue tem que ser todo dia.
                  </p>
                  <p>
                    Você já sabe o que fazer: os pratinhos de planta devem estar com areia, as
                    garrafas precisam estar viradas pra baixo e a caixa-d’água deve estar tampada. O
                    lixo tem que estar bem fechado em uma lixeira com tampa e o quintal limpo e
                    livre de entulhos.
                  </p>
                  <p>
                    Aproveite agora e olhe no seu quintal, na sua varanda, no seu jardim, se não tem
                    nada que possa acumular água. Uma tampinha de garrafa pode se tornar um
                    criadouro.
                  </p>
                  <p>
                    A temporada das chuvas já começou e a gente não pode descuidar. Dengue, a hora
                    de combater é agora.
                  </p>
                  <p>GDF</p>
                </section>
              </div>

              <div v-if="campaign.id == '11-2024-balanco-institucional-final-de-ano'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>PEÇA: Saúde</strong>
                  </p>
                  <p>LOCUTOR: Pessoal, presta atenção aqui...</p>
                  <p>
                    Os investimentos na saúde começam a fazer diferença na vida das pessoas. Já
                    foram entregues doze UBSs, sete UPAs e outras sete estão em construção.
                  </p>
                  <p>
                    Mas a gente sabe que não basta ter o prédio, tem que colocar pra funcionar, aí o
                    GDF nomeou e contratou 7 mil profissionais de saúde. É muita gente....
                  </p>
                  <p>
                    E quer ver o que mais que foi feito? Pagamento retroativo; aumento de salário;
                    plano de saúde e plano odontológico para os servidores. E não para aí não...
                  </p>
                  <p>
                    Tem o Programa Remédio em Casa que faz uma média de dez mil entregas gratuitas
                    de medicamentos de alto custo por mês.
                  </p>
                  <p>
                    E a novidade é o “Programa Saúde mais perto do Cidadão” que já realizou mais de
                    96 mil atendimentos através de carretas médicas com 14 especialidades circulando
                    pelo DF e levando consultas e exames para as cidades.
                  </p>
                  <p>
                    Investimentos como esses melhoram ainda mais a melhor capital do Brasil para se
                    viver. Este GDF faz mais pra fazer melhor.
                  </p>
                </section>
                <section class="ml-4 mb-12">
                  <p>
                    <strong>PEÇA: Renova DF</strong>
                  </p>
                  <p>Apresentador fala descontraidamente com seu público.</p>
                  <p>
                    Gente, sabe o que a maioria das pessoas mais precisa? Oportunidade. É isso. Tem
                    muita gente que quer e pode trabalhar, mas não encontra uma chance.
                  </p>
                  <p>
                    É por isso que este GDF fez o Renova DF, um programa de qualificação
                    profissional inédito no país.
                  </p>
                  <p>
                    É isso aí, ensina as pessoas uma profissão. Os alunos têm um curso de 3 meses
                    pra aprender profissões como serralheiro, pedreiro, pintor e outras formações e
                    ainda recebem um salário por isso.
                  </p>
                  <p>
                    23 mil pessoas já se formaram. Não é dar o peixe, é ensinar a pescar. O programa
                    também dá oportunidade para moradores de rua, gente. A pessoa começa do nada e
                    sai trabalhando. Legal, né?
                  </p>
                  <p>
                    E tem mais. Agora começou o Vivência Profissional, que é uma continuação do
                    Renova, em parceria com a construção civil, que oferece estágio para os melhores
                    alunos em empresas de construção.
                  </p>
                  <p>
                    Oportunidade de emprego e renda são alguns dos motivos pro DF ser o melhor lugar
                    para se viver no Brasil.
                  </p>
                </section>
              </div>

              <div v-if="campaign.id == '01-2025-campanha-comportamento-seguro-chuvas-2024'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor:</strong>
                  </p>
                  <p>
                    Época de férias é bom demais, né? É quando a gente descansa, dá aquela relaxada,
                    aproveita pra passear e até dar uma viajadinha.
                  </p>
                  <p>
                    Mas se liga! Nesse período, mais do que nunca, você não pode abrir mão da
                    segurança para que as suas férias não se transformem em uma grande dor de
                    cabeça.
                  </p>
                  <p>
                    Por isso, faça a revisão preventiva e mantenha seu veículo sempre em boas
                    condições. Cheque os pneus, teste os freios, veja o nível do óleo e confira as
                    luzes, como faróis, setas, pisca-alerta, luz de freio e de ré. O Detran-DF e o
                    GDF sabem que imprevistos podem acontecer, mas se a gente fizer a nossa parte,
                    deixando o carro com tudo em ordem, a chance deles surgirem reduz bastante.
                  </p>
                  <p>Afinal, no trânsito, bom é chegar bem. Desacelere. Seu bem maior é a vida.</p>
                </section>
              </div>

              <div v-if="campaign.id == '01-2025-comportamento-seguro-manutencao-preventiva-2025'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor:</strong>
                  </p>
                  <p>
                    Época de férias é bom demais, né? É quando a gente descansa, dá aquela relaxada,
                    aproveita pra passear e até dar uma viajadinha.
                  </p>
                  <p>
                    Mas se liga! Nesse período, mais do que nunca, você não pode abrir mão da
                    segurança para que as suas férias não se transformem em uma grande dor de
                    cabeça.
                  </p>
                  <p>
                    Por isso, faça a revisão preventiva e mantenha seu veículo sempre em boas
                    condições. Cheque os pneus, teste os freios, veja o nível do óleo e confira as
                    luzes, como faróis, setas, pisca-alerta, luz de freio e de ré.
                  </p>
                  <p>
                    O Detran-DF e o GDF sabem que imprevistos podem acontecer, mas se a gente fizer
                    a nossa parte, deixando o carro com tudo em ordem, a chance deles surgirem reduz
                    bastante.
                  </p>
                  <p>Afinal, no trânsito, bom é chegar bem. Desacelere. Seu bem maior é a vida.</p>
                </section>
              </div>

              <div v-if="campaign.id == '02-2025-comportamento-seguro-volta-as-aulas-2025'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor:</strong>
                  </p>
                  <p>
                    As férias acabaram e as aulas estão de volta. Portanto, vamos redobrar a nossa
                    atenção e cuidados no trânsito.
                  </p>
                  <p>Confira as discas do Detran-DF para garantir a segurança de todos:</p>
                  <p>
                    Se você for transportar crianças no carro, preste atenção nos dispositivos de
                    segurança adequados para cada idade: de 1 a 4 anos, na cadeirinha; de 4 a 7 anos
                    e meio, no assento de elevação; e a partir dos 7 anos e meio, com o cinto de
                    segurança, no banco de trás do veículo.
                  </p>
                  <p>
                    Além disso, é fundamental reduzir a velocidade nas imediações das escolas e
                    sempre respeitar a sinalização, especialmente, as faixas de pedestres.
                  </p>
                  <p>
                    Ao estacionar, não pare em fila dupla ou em vagas especiais, para não prejudicar
                    outros motoristas. E se for contratar transporte escolar, faça uma pesquisa
                    detalhada para garantir que tudo esteja regularizado.
                  </p>
                  <p>
                    No trânsito, a maior lição é a segurança. Desacelere. Seu bem maior é a vida.
                  </p>
                  <p>
                    <b> Detran-DF e GDF. </b>
                  </p>
                </section>
              </div>

              <div v-if="campaign.id == '02-2025-cartao-material-escolar'">
                <section class="ml-4 mb-12">
                  <p>
                    <strong>Locutor:</strong>
                  </p>
                  <p>
                    Vamos falar de um assunto importante, agora, para quem tem filhos em idade
                    escolar.
                  </p>
                  <p>
                    Todo começo de ano é a mesma história. Tem que comprar caderno, mochila, lápis,
                    canetas. A lista é longa, não é mesmo?
                  </p>
                  <p>
                    Para muita gente, é um desafio e tanto fazer as contas caberem no orçamento.
                  </p>
                  <p>Principalmente famílias de baixa renda.</p>
                  <p>
                    Muitas famílias precisam deixar de comer para conseguirem arcar com as despesas
                    extras e manterem os filhos na escola.
                  </p>
                  <p>Foi pensando nessas famílias que o GDF criou o Cartão Material Escolar.</p>
                  <p>Uma ajuda e tanto para quem mais precisa.</p>
                  <p>
                    Sem falar que o benefício também movimenta a economia com as papelarias
                    cadastradas.
                  </p>
                  <p>
                    Muitas delas deixaram de fechar as portas graças aos novos clientes que vieram
                    por meio do Cartão. Bom demais, não é mesmo?
                  </p>
                  <p>E olha que notícia boa: o GDF acaba de ampliar o Cartão Material Escolar.</p>
                  <p>Agora são 200 mil alunos e 500 papelarias beneficiadas.</p>
                  <p>Isso sim é reforço escolar.</p>
                  <p>
                    <b> GDF </b>
                  </p>
                </section>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row id="censure" class="text-center">
          <v-col cols="12">
            <h2
              class="text-h4 font-weight-light font-italic py-8"
              :style="{ color: companiesDict[campaign.ownerId].color }"
            >
              CENSURA
            </h2>
          </v-col>
        </v-row>
        <v-row class="pa-4">
          <v-col cols="12">
            <v-card v-if="campaign">
              <v-card-title>
                {{ campaign.name }}
              </v-card-title>
              <v-card-subtitle>{{ campaign.client }}</v-card-subtitle>
              <v-card-text class="">
                <v-row>
                  <v-col cols="12">
                    <strong>Qtde.:</strong>
                    <span>
                      {{ campaign.censuresData ? campaign.censuresData.length : 0 }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>P.i.:</strong>
                    <span>
                      {{
                        campaign.piCamp
                          ? campaign.piCamp
                          : campaign.censuresData
                          ? campaign.censuresData[0].pi
                          : ""
                      }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>Período:</strong>
                    <span>
                      {{
                        campaign.timeFrame
                          ? campaign.timeFrame
                          : campaign.censuresData
                          ? campaign.censuresData[0].periodo
                          : ""
                      }}
                    </span>
                    <span class="mx-2"> | </span>
                    <strong>Tipo/Duração:</strong>
                    <span>
                      {{
                        campaign.type
                          ? campaign.type
                          : campaign.censuresData
                          ? campaign.censuresData[0].tipo
                          : ""
                      }}/{{
                        campaign.format
                          ? campaign.format
                          : campaign.censuresData
                          ? campaign.censuresData[0].formato
                          : ""
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="campaign.censuresData"
                  :search="search"
                  :sort-by="['idSecom', 'veiculacao']"
                  :sort-desc="[false, false]"
                  show-expand
                  multi-sort
                  :expanded.sync="expanded"
                  :footer-props="{
                    'items-per-page-all-text': 'Todas',
                    'items-per-page-text': 'Censuras por página:',
                    'items-per-page-options': [15, 50, 100, -1],
                  }"
                  class="elevation-1"
                  item-key="id"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>CENSURAS</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pb-8 pt-6 grey--text text--darken-1">
                      <strong>Programa:</strong> {{ item.programa }}
                      <span class="mx-2"> | </span>
                      <strong>Apresentador:</strong> {{ item.apresentador }}
                      <span class="mx-2"> | </span>
                      <strong>Hor. Início:</strong> {{ item.inicio }} <span class="mx-2"> | </span>
                      <strong>Hor. Fim:</strong>
                      {{ item.fim }}
                    </td>
                  </template>
                  <template v-slot:[`item.audio`]="{ item }">
                    <div v-if="item.audioData">
                      <v-icon
                        small
                        :color="companiesDict[campaign.ownerId].color"
                        @click="openAudioPlayer(item)"
                      >
                        mdi-play-circle
                      </v-icon>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <p class="mt-4">
                      Nenhuma censura encontrada! Os registros cadastrados aparecerão aqui.
                    </p>
                  </template>
                  <template v-slot:no-results>
                    <p class="mt-4">Nenhuma resultado encontrado para busca: {{ search }}</p>
                  </template>
                </v-data-table>
              </v-card-text>

              <v-card-actions class="pb-4 pr-4">
                <v-spacer></v-spacer>
                <v-btn
                  small
                  dark
                  :color="companiesDict[campaign.ownerId].color"
                  @click="expandAll()"
                >
                  {{ expanded.length ? "Fechar" : "Expandir" }} Todos
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="grey lighten-2 mt-12" id="footer">
        <v-container>
          <v-row id="censure" class="text-center my-0">
            <v-col cols="12">
              <!-- <p>footer</p> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <!-- bottom audio player Dialog -->
    <v-bottom-sheet inset v-model="playerDialog" @click:outside="closeAudioPlayer()">
      <v-card tile>
        <v-progress-linear :value="100" class="my-0" height="3"></v-progress-linear>

        <v-container class="pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" sm="4">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ audioPlayerData.veiculo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ audioPlayerData.apresentador }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ audioPlayerData.programa }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6" class="mt-3 d-flex justify-center">
              <v-btn
                class="mr-2 mt-2 elevation-0 fixed-bottom"
                dark
                small
                color="primary"
                @click="playSprite()"
              >
                <v-icon dark small> mdi-play </v-icon>
                Tocar o trecho da Censura
              </v-btn>
              <audio
                v-if="audioPlayerData"
                :src="audioPlayerData.src"
                ref="audioPlayer"
                controls
                style="width: 100%"
              ></audio>
            </v-col>
            <v-col cols="12" sm="2" class="mb-8 mb-sm-2">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ audioPlayerData.veiculacao }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ audioPlayerData.time }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="caption">
                      {{ audioPlayerData.numInsercao }} |
                      {{ audioPlayerData.numTexto }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { db, onSnapshot, collection, query, where } from "@/firebase/firebase";

export default {
  name: "Campaign",

  components: {
    //
  },

  mounted() {
    if (this.$route.params.id) {
      // this.subscribeListerner();
      this.$store.dispatch("loadCampaignWithOnlyPublishedCensures", this.$route.params.id);
    }
  },

  destroyed() {
    this.unsubscribeListener();
    if (this.campaign) this.$store.dispatch("loadCampaignWithCensuresData", this.campaign.id);
  },

  data() {
    return {
      selectedDate: null,
      headers: [
        {
          text: "Data Veic.",
          sortable: true,
          value: "veiculacao",
        },
        {
          text: "Horário",
          sortable: true,
          value: "audioData.time",
        },
        {
          text: "Agência",
          sortable: true,
          value: "agencia",
        },
        {
          text: "iD Secom",
          sortable: true,
          value: "idSecom",
        },
        {
          text: "Pi Veículo",
          sortable: true,
          value: "piVeiculo",
        },
        {
          text: "Estado",
          sortable: true,
          value: "uf",
        },
        {
          text: "Município",
          sortable: true,
          value: "municipio",
        },
        {
          text: "Veículo",
          sortable: true,
          value: "veiculo",
        },
        {
          text: "Apresentador",
          sortable: true,
          value: "apresentador",
        },
        { text: "Áudio", value: "audio", sortable: false, align: "center" },
      ],
      search: "",
      expanded: [],
      censureList: null,
      internalLoading: null,
      unsubscribeListener: null,
      playerDialog: false,
      audioPlayerData: {},
      selectedAudio: null,
      companiesDict: {
        "9nlUNqeCN3YdNNlMCpIT0klgVSm1": {
          name: "Direct Áudio",
          color: "#a14533",
        },
        GvICO95OiaMzK1GRGJwkEownAkd2: {
          name: "ProjetoR Áudio",
          color: "#6e8889",
        },
        CqYaTVdFuZOtcxqtN4d7dYjQ5pu1: {
          name: "Rooftop Produções",
          color: "#04989e",
        },
        CDpLVkW9NfTHkGm6QuKP8219kiS2: {
          name: "Atabaque Música",
          color: "#f55f2d",
        },
      },
    };
  },

  computed: {
    campaign() {
      return this.$store.getters.getCampaign;
    },
    feedback() {
      return this.$store.getters.getFeedback;
    },
    user() {
      return this.$store.getters.getUser;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },

  watch: {
    campaign(v) {
      if (v) {
        this.subscribeListerner();
      }
    },
  },

  methods: {
    expandAll() {
      // console.log(this.expanded.length);
      if (!this.expanded.length) {
        this.expanded = this.campaign.censuresData;
      } else {
        this.expanded = [];
      }
    },
    subscribeListerner() {
      console.log("Subscribe Listener");
      this.internalLoading = true;
      const q = query(
        collection(db, "campaigns", this.$route.params.id, "censuresData"),
        where("status", ">=", 2)
      );
      this.unsubscribeListener = onSnapshot(q, (querySnapshot) => {
        const newCampaign = this.campaign;
        if (newCampaign) newCampaign.censuresData = [];
        querySnapshot.forEach((doc) => {
          const newCensure = {
            id: doc.id,
            ...doc.data(),
          };
          const day = newCensure.veiculacao.toDate().getDate() + "";
          const month = newCensure.veiculacao.toDate().getMonth() + 1 + "";
          const year = newCensure.veiculacao.toDate().getFullYear();
          newCensure.veiculacao = `${year}/${month.length === 1 ? "0" + month : month}/${
            day.length === 1 ? "0" + day : day
          }`;
          // newCensure.veiculacao = newCensure.veiculacao.toDate().toLocaleDateString('pt-BR');
          newCampaign.censuresData.push(newCensure);
        });
        this.$store.dispatch("UpdateVuexCampaign", newCampaign);
      });
    },
    isOdd(num) {
      return num % 2;
    },
    playAudio2(id, src, start) {
      const player = document.getElementById(id);
      player.src = src;
      player.type = "audio/mpeg";
      if (player.paused == true) {
        if (start) player.currentTime = start;
        player.play();
      } else {
        player.pause();
      }
    },
    openAudioPlayer(item) {
      console.log("Open Player Dialog");
      this.selectedAudio = item.audioData;
      this.playerDialog = true;
      this.audioPlayerData.src = item.audioData.audioUrl;
      this.audioPlayerData.veiculo = item.veiculo;
      this.audioPlayerData.apresentador = item.apresentador;
      this.audioPlayerData.programa = item.programa;
      this.audioPlayerData.veiculacao = item.veiculacao;
      this.audioPlayerData.time = item.audioData.time;
      this.audioPlayerData.numTexto = item.numTexto;
      this.audioPlayerData.numInsercao = item.numInsercao;
      console.log(this.player);
    },
    closeAudioPlayer() {
      this.audioPlayerData = {};
      // this.audioPlayerData.src = null;
      this.$refs.audioPlayer.src = null;
      this.selectedAudio = null;
      console.log("closing...");
    },
    playSprite() {
      const player = this.$refs.audioPlayer;
      const initM = this.selectedAudio.cut.inicioMinutos;
      const initS = this.selectedAudio.cut.inicioSegundos;
      const finalM = this.selectedAudio.cut.fimMinutos;
      const finalS = this.selectedAudio.cut.fimSegundos;
      const initialTime = parseInt(initM) * 60 + parseInt(initS);
      let finalTime = parseInt(finalM) * 60 + parseInt(finalS);
      if (finalTime < 1) finalTime = player.duration;
      if (initialTime > finalTime)
        this.audioFeedback = "Tempo inicial do corte está maior que o tempo final...";
      if (player && initialTime <= finalTime) {
        player.currentTime = initialTime;
        this.spriteStatus = true;
        player.play();
        const playInterval = setInterval(() => {
          if (player.currentTime >= finalTime) {
            player.pause();
            clearInterval(playInterval);
            this.spriteStatus = false;
          }
        }, 1000);
      }
    },
    secondsToMinutes(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`;
    },
  },
};
</script>

<style scoped>
.Campaign {
  padding: 0;
}
#footer {
  height: 400px;
}
.push-top-right {
  position: absolute;
  right: 21px;
  top: 21px;
}
.fixed-bottom {
  position: absolute;
  bottom: -3px;
}
</style>
